import { nextTick } from "vue";

export default defineNuxtRouteMiddleware(async () => {
  const localePath = useLocalePath();
  const partnerStore = usePartnerStore();
  const route = useRoute();

  if (!partnerStore?.partner) {
    await partnerStore.getDetailData(route.params.partner as string);
  }

  if (partnerStore.partner?.parent) {
    await nextTick();
    return navigateTo(localePath({ name: "partner", params: { partner: partnerStore.partner?.parent?.seo_name }}), { external: true });
  }
});
